/**
 * This helper retrieves size tokens for a particular size (small, medium, large, extra-large).
 *
 * @param size - The current size of the CloseButton.
 *
 * @returns A new object containing the button and icon size.
 */
export const getSizeTokens = size => {
  // Base tokens for common properties across variants
  const sizeTokens = {
    small: {
      padding: '50',
      iconSize: '200'
    },
    medium: {
      padding: '100',
      iconSize: '300'
    },
    large: {
      padding: '200',
      iconSize: '400'
    },
    'extra-large': {
      padding: '400',
      iconSize: '800'
    }
  };
  return sizeTokens[size];
};

/**
 * This helper retrieves variant tokens for a particular variant.
 *
 * @param variant - The current variant of the CloseButton.
 *
 * @returns A new object containing tokens for the variant's idle, hover, active, and disabled states.

 */
export const getVariantTokens = variant => {
  const tokens = {
    inverse: {
      idle: {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        color: 'icon-primary-on-fill-default'
      },
      hover: {
        backgroundColor: 'rgba(255, 255, 255, .1)',
        color: 'icon-primary-on-fill-default'
      },
      active: {
        backgroundColor: 'rgba(255, 255, 255, .1)',
        color: 'icon-primary-on-fill-default'
      },
      disabled: {
        backgroundColor: 'rgba(255, 255, 255, .1)',
        color: 'rgba(255, 255, 255, 0.6)',
        cursor: 'not-allowed'
      }
    },
    default: {
      idle: {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        color: 'icon-primary-default'
      },
      hover: {
        backgroundColor: 'rgba(255, 255, 255, .1)',
        color: 'icon-primary-hover'
      },
      active: {
        backgroundColor: 'rgba(255, 255, 255, .1)',
        color: 'icon-primary-default'
      },
      disabled: {
        backgroundColor: 'rgba(255, 255, 255, .1)',
        color: 'rgba(124, 152, 182, 0.6)',
        cursor: 'not-allowed'
      }
    }
  };
  return tokens[variant];
};