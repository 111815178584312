import { useCallback } from 'react';
import { useControlledState } from '../useControlledState';
/**
 * Manages the open/closed state of a component.
 */
export const useControlledOpen = ({
  controlledOpen,
  onOpenChange
}) => {
  const {
    value: isOpen,
    setValue: setInternalOpen
  } = useControlledState(controlledOpen, false);
  const setIsOpen = useCallback(newOpen => {
    setInternalOpen(newOpen);
    onOpenChange === null || onOpenChange === void 0 || onOpenChange(newOpen);
  }, [setInternalOpen, onOpenChange]);
  return {
    isOpen,
    setIsOpen
  };
};