import { isNested } from '../../utils/select-actionsmenu';

/**
 * Get padding values of the reference element, aka the trigger element
 * that toggles the menu.
 */
export const getReferencePadding = theme => {
  return {
    paddingBlock: theme.space[200],
    paddingInline: theme.space[300]
  };
};
export const getReferenceLayout = (referenceIndicator, referenceText) => {
  if (referenceIndicator && referenceText) {
    return 'indicator-text';
  } else if (referenceIndicator && !referenceText) {
    return 'indicator-only';
  } else {
    return 'text-only';
  }
};
export const getFlattenedActions = actionsArray => {
  return actionsArray.flatMap(currentAction => {
    if (isNested(currentAction)) {
      const {
        subheading,
        actions
      } = currentAction;
      return [{
        text: subheading,
        value: subheading,
        isSubheading: true
      }, ...actions.map((action, index) => Object.assign({}, action, {
        divider: index === actions.length - 1
      }))];
    } else {
      return [currentAction];
    }
  });
};