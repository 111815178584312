// This file contains logic carried over from UIComponents.
// It should not be used in new Foundations components, only for transitional components.

export const DISTANCE_SIZES = {
  flush: '0',
  xs: '200',
  sm: '300',
  md: '600',
  lg: '800',
  xl: '1400'
};